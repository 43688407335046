import request from "./request";
import crewordRequest from "./crewordRequest";
// 根据ID查询商品详情
export function searchID(params) {
  return request({
    url: "/product/one",
    method: "get",
    // data,
    params,
  });
}

// 搜索查询商品图片
export function searchFile(data) {
  return request({
    url: "/product/product-file/search",
    method: "post",
    data,
  });
}

// 搜索查询商品规格
export function searchSpecification(data) {
  return request({
    url: "/product/product-specification/search",
    method: "post",
    data,
  });
}

// 获取版权实务承办人
export function FindPromoter(data) {
  return request({
    url: "/project/findPromoter",
    method: "post",
    data,
  });
}

// 新增版权实务订单
export function AddCopyrightPractice(data) {
  return request({
    url: "/product/order/addCopyrightPractice",
    method: "post",
    data,
  });
}

// 新增订单
export function orderAdd(data) {
  return request({
    url: "/product/order/add",
    method: "post",
    data,
  });
}
// 新增订单
export function alipayQueryOrder(data) {
  return request({
    url: "/alipay/queryOrder",
    method: "post",
    data,
  });
}

// 授权生成支付二维码
export function payOtherOrderPay(data) {
  return request({
    url: "/pay/orderPay",
    method: "post",
    data,
  });
}

// 调用商品微信支付的查询订单接口
export function PaywechatOrderQueryQrcode(data) {
  return request({
    url: "/pay/wechatOrderQueryQrcode",
    method: "post",
    data,
  });
}

//信息汇编搜索
export function queryOfficicalWord(data) {
  return request({
    url: "/copyright/queryOfficicalWord",
    method: "post",
    data,
  });
}

//获取主题
export function findTheme(params) {
  return request({
    url: "/side/findTheme",
    method: "get",
    // data,
    params,
  });
}

//立即添加
export function addUserLibrary(data) {
  return request({
    url: "/side/addUserLibrary",
    method: "post",
    data,
  });
}

// 购买校验
export function sideIsbuy(data) {
  return request({
    url: "/side/isbuy",
    method: "post",
    data,
  });
}

// 获取优惠接口
export function findDiscount(data) {
  return request({
    url: "/recommendcode/findDiscount",
    method: "post",
    data,
  });
}
// 获取vip优惠接口
export function findDiscountByUser(data) {
  return request({
    url: "/recommendcode/findDiscountByUser",
    method: "post",
    data,
  });
}

export function findDiscountInfoByCode(data) {
  return request({
    url: "/recommendcode/findDiscountInfoByCode",
    method: "post",
    data,
  });
}

// 绑定推荐码
export function bind(data) {
  return request({
    url: "recommendcode/bind",
    method: "post",
    data,
  });
}

// 获取版权实务规格
export function FindProcess(data) {
  return request({
    url: "/project/findProcessAndArea",
    method: "post",
    data,
  });
}

// 获取评论列表
export function commentList(params) {
  return request({
    url: "/productevaluate/list",
    method: "get",
    params,
  });
}

// 下载版权诉讼指引
export function exportProjectFile(params) {
  return request({
    url: "/projectdetail/exportProjectFile",
    method: "get",
    params,
    responseType: "blob",
  });
}

// 增删收藏商品
export function addOrDel(data) {
  return request({
    url: "/productcollection/addOrDel",
    method: "post",
    data,
  });
}
