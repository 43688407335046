<template>
  <div class="KnowledgeServiceNav" :class="clickIndex !== -1 ? 'isIndex' : ''">
    <!-- <div @click="handleBack" class="back" v-if="clickIndex !== -1">
      <img :src="require('assets/images/knowledgeService/slide_left.png')" />
      返回
    </div> -->
    <div class="nav_box">
      <!-- <div
        class="nav_item_box"
        :class="clickIndex == index ? 'blue' : ''"
        v-for="(item, index) in navList"
        :key="index"
        @click="handleclick(index, item.paht)"
      >
        <img class="img" :src="item.imgSrc" alt="" />
        <span class="txet"> {{ item.text }} </span>
      </div> -->
      <!-- <div class="chathead">
        <img
          class="img"
          :src="require('assets/images/knowledgeService/iocn3.png')"
          alt=""
        />
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      default: -1,
    },
  },
  name: "knowledgeServiceNav",
  data() {
    return {
      clickIndex: this.index,
      navList: [
        // {
        //   imgSrc: require("assets/images/knowledgeService/gouwuche.png"),
        //   text: "我的购物车",
        //   paht: "knowledgeServiceShoppingCart",
        // },
        {
          imgSrc: require("assets/images/knowledgeService/order.png"),
          text: "订单",
          paht: "knowledgeServiceOrder",
        },
      ],
    };
  },
  methods: {
    handleclick(index, name) {
      this.clickIndex = index;
      this.$router.push({
        name: name,
        params: { index },
      });
    },

    handleBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.isIndex {
  display: flex;
  justify-content: space-between;
}
.KnowledgeServiceNav {
  .back {
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    img {
      display: block;
      width: 7px;
      height: 10px;
      margin-right: 5px;
    }
  }
  .nav_box {
    display: flex;
    justify-content: flex-end;
    .blue {
      color: #ff6900;
    }
    .nav_item_box {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      font-size: 13px;
      .img {
        margin-right: 5px;
        width: 16px;
        height: 16px;
      }
    }

    .chathead {
      .img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>
