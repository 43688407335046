<template>
  <div class="IdiomCoupon">
    <h2>成语券</h2>
    <div class="ti_ts">
      成语券是“成语极速版”等系列产品中智能内容服务、专业服务的支付媒介。
    </div>
    <div class="ban_img">
      <img src="~assets/images/IdiomCoupon/ban_img.png" alt="" />
    </div>
    <div class="food">
      <div class="food_text" v-for="(v, i) in fooList" :key="i">
        <div class="foo_tit">
          <h3>应用场景{{ i + 1 }}:</h3>
          <img class="icon" :src="v.icon" alt="" />
          <div class="txt">{{ v.name }}</div>
        </div>
        <div class="text">{{ v.context }}</div>
        <div class="text_img">
          <div class="lef_img">
            <img :src="v.img" alt="" srcset="" />
            <div>(场景图)</div>
          </div>

          <div class="rig_video">
            <img :src="v.img" alt="" srcset="" />
            <div>(功能视频)</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  name: 'IdiomCoupon',
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      fooList: [
        {
          icon: require('assets/images/IdiomCoupon/AIwitter.png'),
          name: 'AI成文',
          context: '1券起智能文本生成，创作省时更省心！',
          img: require('assets/images/IdiomCoupon/AIwitter1.png'),
          video: '',
        },
        {
          icon: require('assets/images/IdiomCoupon/AIGC_icon.png'),
          name: 'AIGC字符包',
          context: '1券兑换1万AIGC字符包，告别“巧妇难为无米之炊”！',
          img: require('assets/images/IdiomCoupon/AIGC_icon1.png'),
          video: '',
        },
        {
          icon: require('assets/images/IdiomCoupon/AI_huha.png'),
          name: 'AI绘画',
          context: '1券1张AI画作，灵感落地美轮美奂！',
          img: require('assets/images/IdiomCoupon/AI_huha1.png'),
          video: '',
        },
        {
          icon: require('assets/images/IdiomCoupon/AI_ptu.png'),
          name: 'AI配图',
          context: '1券1张AI配图，拒绝“图片版权侵权”。',
          img: require('assets/images/IdiomCoupon/AI_ptu1.png'),
          video: '',
        },
        {
          icon: require('assets/images/IdiomCoupon/AI_PPT.png'),
          name: 'AI生成PPT',
          context: '1券1张PPT，不再担心“各种催活”。',
          img: require('assets/images/IdiomCoupon/AI_PPT1.png'),
          video: '',
        },
        {
          icon: require('assets/images/IdiomCoupon/AI_FW.png'),
          name: '数字人服务',
          context: '1券起尽享特色数字人服务，高效、便捷、到位！',
          img: require('assets/images/IdiomCoupon/AI_ZYFW1.png'),
          video: '',
        },
        {
          icon: require('assets/images/IdiomCoupon/AI_ZYFW.png'),
          name: '专业人士服务',
          context: '1券起尽享专业服务，专业、省心、给力！',
          img: require('assets/images/IdiomCoupon/AI_ZYFW1.png'),
          video: '',
        },

        {
          icon: require('assets/images/IdiomCoupon/seed.png'),
          name: '其他成语券支付场景',
          context: '期待新际遇、新发现、新收获！',
          img: require('assets/images/IdiomCoupon/seed1.png'),
          video: '',
        },
      ],
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
.IdiomCoupon {
  color: #333;
  h2 {
    font-size: 30px;
    margin: 47px;
    text-align: center;
  }
  .ti_ts {
    font-size: 16px;
    margin-bottom: 40px;
  }
  .ban_img {
    width: 100%;
    text-align: center;
    margin-bottom: 60px;
    img {
      width: 600px;
    }
  }
  .food_text {
    margin-top: 82px;
    .foo_tit {
      display: flex;
      align-items: center;
      font-size: 20px;
      .icon {
        width: 26px;
        margin: 0 4px 0 8px;
      }
      .txt {
        color: #ff6500;
      }
    }
    .text {
      font-size: 16px;
      margin: 27px 0 32px 0;
    }
    .text_img {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      color: #999999;
      text-align: center;
      .lef_img {
        img {
          width: 560px;
        }
      }
      .rig_video {
        img {
          width: 560px;
        }
      }
    }
  }
}
</style>
