<template>
  <div class="picture-showcase">
    <el-carousel
      arrow="always"
      :autoplay="false"
      indicator-position="none"
      ref="carousel"
    >
      <el-carousel-item v-for="(item, index) in imgList" :key="index">
        <img :src="$store.state.onlineBasePath + item.fileUrl" alt="" />
      </el-carousel-item>
      <ul class="mycarousel-indicators">
        <li
          class="mycarousel-indicator"
          v-for="(item, index) in imgList"
          :key="index"
          @click="setActiveIndex(index)"
          v-show="index < 4"
        >
          <img :src="$store.state.onlineBasePath + item.fileUrl" alt="" />
        </li>
      </ul>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "PictureShowcase",
  props: {
    // 商品图片
    imgList: {},
  },
  data() {
    return {};
  },
  methods: {
    setActiveIndex(index) {
      this.$refs["carousel"].setActiveItem(index);
    },
  },
  watch: {},
  created() {},
};
</script>

<style lang="scss" scoped>
::v-deep .el-carousel__arrow.el-carousel__arrow--left {
  top: 350px;
  width: 17px;
  height: 29px;
  left: 0px;
  background: none;
  &::before {
    content: "";
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 17px;
    height: 29px;
    background: url("~assets/images/goodsGetails/detail_slide_left.png")
      no-repeat;
    background-size: 100% 100%;
  }
  i::before {
    content: "";
  }
}
::v-deep .el-carousel__arrow.el-carousel__arrow--right {
  top: 350px;
  width: 17px;
  height: 29px;
  right: 0px;
  background: none;
  &::before {
    content: "";
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 17px;
    height: 29px;
    background: url("~assets/images/goodsGetails/detail_slide_right.png")
      no-repeat;
    background-size: 100% 100%;
  }
  i::before {
    content: "";
  }
}
.picture-showcase {
  width: 300px;
  box-sizing: border-box;
  ::v-deep .el-carousel {
    .el-carousel__container {
      height: 382px;
      .el-carousel__item {
        img {
          width: 298px;
          height: 300px;
        }
      }
    }
  }
  img {
    border: 1px solid #e9e9e9;
  }
  .mycarousel-indicators {
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    z-index: 2;
    padding: 0 20px;
    top: 315px;
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    .mycarousel-indicator {
      border: 1px solid #e9e9e9;
      width: 60px;
      height: 60px;
      cursor: pointer;
      img {
        image-rendering: -webkit-optimize-contrast;
        width: 60px;
        height: 60px;
      }
    }
  }
}
</style>
