<template>
  <div class="GoodsBottomTab">
    <div class="copyright-practice-detail-content-tabs">
      <el-tabs v-model="activeTab" type="card">
        <el-tab-pane name="first">
          <span class="tabs-title" slot="label">{{
            type == 1 ? '基本信息' : '简介'
          }}</span>

          <div class="goods-tab-item">
            <div v-if="$route.params.id == 1">
              <VIPcenter />
            </div>
            <div class="goods-tab-item" v-else>
              <IdiomCoupon />
            </div>
            <div class="stencil_list" v-if="goodsData.productType == 6">
              <div class="btn" @click="handleClikc">点击查看该模板列表</div>
            </div>
          </div>
        </el-tab-pane>

        <el-tab-pane label="用户评论" name="third">
          <span class="tabs-title" slot="label"
            >用户评论({{ comment.length }})</span
          >
          <div class="goods-tab-item">
            <div class="sm-goods-evaluate">
              <div class="evaluate-header">
                <div class="evaluate-header-left">
                  <div class="evaluate-header-li">
                    优秀评论({{ comment.length }}）
                  </div>
                </div>
                <div class="evaluate-header-right">
                  <div class="evaluate-percent">99 <span>%</span></div>
                  <div class="evaluate-txt">好评度</div>
                </div>
              </div>
              <div class="evaluate-content">
                <ul class="evaluate-content-tab">
                  <div
                    class="evaluate-content-tab-li"
                    :class="all ? 'evaluate-content-tab-li-ac' : ''"
                    @click="handleAllRate"
                  >
                    全部评价
                  </div>
                  <div
                    class="evaluate-content-tab-li"
                    :class="good ? 'evaluate-content-tab-li-ac' : ''"
                    @click="Praise"
                  >
                    好评
                  </div>
                  <div
                    class="evaluate-content-tab-li"
                    :class="med ? 'evaluate-content-tab-li-ac' : ''"
                    @click="Medium"
                  >
                    中评
                  </div>
                  <div
                    class="evaluate-content-tab-li"
                    :class="bad ? 'evaluate-content-tab-li-ac' : ''"
                    @click="badpost"
                  >
                    差评
                  </div>
                  <div class="evaluate-content-tab-li">时间排序</div>
                </ul>
                <ul class="evaluate-content-li">
                  <li
                    v-for="(v, i) of commentCopy"
                    :key="i"
                    class="evaluate-content-item"
                  >
                    <div class="evaluate-item-avatar">
                      <img
                        :src="
                          v.userheadImg
                            ? v.userheadImg
                            : require('assets/images/user.png')
                        "
                        @error="userheadImg(v)"
                        alt=""
                      />
                      <span v-html="v.nickName"></span>
                    </div>
                    <div class="evaluate-item-content">
                      <el-rate v-model="v.score" :disabled="true"> </el-rate>
                      <p
                        class="evaluate-item-content-text"
                        v-html="v.content"
                      ></p>
                      <ul class="evaluate-item-content-imgs">
                        <li
                          v-for="(u, index) of v.evaluateImgs"
                          :key="index"
                          class="evaluate-item-content-img"
                        >
                          <img :src="$store.state.onlineBasePath + u" alt="" />
                        </li>
                      </ul>
                      <ul class="evaluate-item-content-actionbar">
                        <li
                          class="evaluate-item-content-actionbar-date"
                          v-html="v.createTime"
                        ></li>
                        <li class="evaluate-item-content-actionbar-report">
                          举报
                        </li>
                        <li class="evaluate-item-content-actionbar-fabu">
                          <img
                            :src="require('assets/images/fabu.png')"
                            alt=""
                          />
                          赞(<span>0</span>)
                        </li>
                        <li
                          class="evaluate-item-content-actionbar-reply"
                          @click="replyClick(v)"
                        >
                          <img
                            :src="require('assets/images/reply.png')"
                            alt=""
                          />
                          回复(<span>0</span>)
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
                <div class="nomessage" v-if="commentCopy.length == 0">
                  <img :src="require('assets/images/no-message.png')" />
                  <div>暂无评价</div>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <el-dialog
      class="template_list"
      title="模板列表"
      :visible.sync="dialogVisible"
      width="50%"
      lock-scroll
    >
      <div class="content">
        <div class="search">
          <el-input
            placeholder="请输入内容"
            v-model="search"
            class="input-with-select"
            @keyup.enter.native="clickSearch"
          >
            <el-button
              @click="clickSearch"
              slot="append"
              icon="el-icon-search"
            ></el-button>
          </el-input>
        </div>
        <div
          class="stencil_list"
          @scroll="staScrollFun"
          ref="stencil_list"
          style="overflow: auto"
        >
          <div class="box">
            <div class="item" v-for="(item, index) in list" :key="index">
              <img class="img" :src="require('assets/images/doc.png')" />

              <div class="content">
                <div class="title">{{ item.showName }}</div>
                <!-- <div class="template">{{item.template}}</div> -->
                <div class="obj">
                  <div class="date">上传: {{ item.createTime }}</div>
                  <div class="hot">阅读: {{ item.hot }}</div>
                  <div class="size">大小: 未知</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { findTheme, commentList } from 'api/goodsGetails'
import { findOftenWord } from 'api/goldenSentenceIndex.js'
import VIPcenter from 'components/VIPcenter'
import IdiomCoupon from 'components/IdiomCoupon'
export default {
  name: 'GoodsBottomTab',
  components: {
    VIPcenter,
    IdiomCoupon,
  },
  props: {
    goodsData: {},
  },

  data() {
    return {
      // tab
      activeTab: 'first',
      //评论内容
      comment: [],
      processList: [], //商品主题
      // 获取版权实务承办人
      undertakerList: [],
      undertakerPageNum: 1,
      type: null, //商品类型
      id: null, //商品id
      commentCopy: [],
      //评价类型标记
      all: true,
      good: false,
      med: false,
      bad: false,
      // 模板列表
      list: [],
      page: 1,
      dialogVisible: false,
      search: '',
    }
  },
  mounted() {
    console.log(this.goodsData)
    window.OpenStencilList = this.handleClikc
  },
  methods: {
    clickSearch() {
      this.list = []
      this.getlist(this.goodsData.contextualId)
    },
    staScrollFun() {
      let sta = this.$refs.stencil_list
      let offsetHeight = sta.offsetHeight
      let scrollTop = sta.scrollTop
      let scrollHeight = sta.scrollHeight
      if (offsetHeight + scrollTop >= scrollHeight - 20) {
        if (this.list.length >= this.total) {
          return
        }
        //触发事件
        this.page++
        this.getlist(this.goodsData.contextualId)
      }
    },
    handleClikc() {
      this.dialogVisible = true
      if (this.list.length == 0) {
        this.getlist(this.goodsData.contextualId)
      }

      //
    },
    // 获取模板列表
    async getlist(id) {
      const data = {
        page: this.page,
        limit: 20,
        id,
        oftenWord: this.search,
        type: 3,
        sortType: 2,
        sortVal: 2,
      }
      let res = await findOftenWord(data)
      if (res.code != 200) {
        return this.$message({
          type: 'error',
          message: res.message,
        })
      }
      this.total = res.data.total
      this.list = [...this.list, ...res.data.data]
    },

    // 获取商品主题
    async getTheme() {
      if (this.type == 1) {
        return
      }
      const params = {
        type: this.type,
        id: this.id,
      }
      const res = await findTheme(params)
      if (res.code === 200) {
        this.processList = res.data
      }
    },

    //获取评论列表
    async getcommentList() {
      let data = {
        productId: this.$route.params.id,
      }
      let res = await commentList(data)
      if (res.code == 200) {
        this.comment = res.data
        this.commentCopy = this.comment
        if (this.comment.length == 0) {
          this.noinfo = true
        }
      } else {
        this.$message({
          type: 'error',
          message: res.message,
        })
      }
    },
    //全部评价
    handleAllRate() {
      this.commentCopy = this.comment
      this.all = true
      this.good = false
      this.med = false
      this.bad = false
      this.noinfo = false
      if (this.commentCopy.length == 0) {
        this.noinfo = true
      }
    },
    //好评
    Praise() {
      this.all = false
      this.good = true
      this.med = false
      this.bad = false
      this.noinfo = false
      this.commentCopy = []
      for (var a of this.comment) {
        if (a.score == 5) {
          this.commentCopy.push(a)
        }
      }
      if (this.commentCopy.length == 0) {
        this.noinfo = true
      }
    },
    //中评
    Medium() {
      this.all = false
      this.good = false
      this.med = true
      this.bad = false
      this.noinfo = false
      this.commentCopy = []
      for (var a of this.comment) {
        if (a.score != 5 && a.score != 1) {
          this.commentCopy.push(a)
        }
      }
      if (this.commentCopy.length == 0) {
        this.noinfo = true
      }
    },
    //差评
    badpost() {
      this.all = false
      this.good = false
      this.med = false
      this.bad = true
      this.noinfo = false
      this.commentCopy = []
      for (var a of this.comment) {
        if (a.score == 1 || a.score == 0) {
          this.commentCopy.push(a)
        }
      }
      if (this.commentCopy.length == 0) {
        this.noinfo = true
      }
    },
  },
  watch: {
    $route(to, from) {
      this.list = []
      this.page = 1
    },
  },
  created() {
    // console.log(this.$route.params);
    this.id = this.$route.params.id
    this.type = this.$route.params.type
    this.getTheme()
    this.getcommentList()
  },
}
</script>

<style lang="scss" scoped>
.GoodsBottomTab {
  ::v-deep .copyright-practice-detail-content-tabs {
    margin-top: 50px;
    .el-tabs {
      .el-tabs__header {
        margin: 0;
      }
    }
    .goods-tab-item {
      line-height: 1.5;
      width: 100%;
      .process_box {
        background-color: #f7f7f7;
      }
      .process_title {
        text-align: center;
        padding: 50px 0;
        font-weight: 800;
        font-size: 30px;
      }
      .process_list {
        margin: 0 50px;
        border: 1px solid #ccc;
        .process_item {
          display: flex;
          // height: 50px;
          align-items: center;
          border-bottom: 1px solid #ccc;
          .process_name {
            width: 20%;
            padding: 15px 0 15px 20px;
            // height: 50px;
            // line-height: 50px;
          }
          .process_theme {
            width: 100%;
            text-align: center;
            border-left: 1px solid #ccc;
            border-right: 1px solid #ccc;
            padding: 15px 20px 15px 20px;
          }
          // .process_remarks{
          //   width: 50%;
          //   padding: 15px 0 15px 20px;
          // }
          &:last-of-type {
            border: none;
          }
        }
      }
      .imgBox {
        width: 1000px;
        margin: 0 auto;
        text-align: justify;
        text-justify: distribute-all-lines; // 这行必加，兼容ie浏览器 text-align-last: justify;
        .img {
          display: block;
          width: 580px;
        }
      }

      .stencil_list {
        cursor: pointer;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        background-color: #f7f7f7;
      }
    }
    .tabs-title {
      color: #333333;
    }
    .sm-goods-evaluate {
      width: 100%;
    }
    .evaluate-header {
      height: 116px;
      display: flex;
    }
    .evaluate-header-left {
      width: 500px;
      display: flex;
      flex-wrap: wrap;
    }

    .evaluate-header-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .evaluate-header-li {
      margin-left: 10px;
      margin-bottom: 10px;
      padding: 0 9px;
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
      height: 22px;
      background: #ffffff;
      border: 1px solid #e0e0e0;
      cursor: pointer;
    }

    .evaluate-percent {
      position: relative;
      width: 90px;
      height: 70px;
      font-size: 62px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ff6900;
    }

    .evaluate-percent span {
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: 16px;
    }

    .evaluate-txt {
      margin-top: 15px;
      font-size: 14px;
      font-weight: 500;
      color: #444444;
    }

    .evaluate-content {
      width: 100%;
    }

    .evaluate-content-tab {
      display: flex;
      width: 100%;
      height: 37px;
      line-height: 37px;
      background: #f9fbff;
      border: 1px solid #e9f2ff;
      padding: 0 20px;
      box-sizing: border-box;
    }

    .evaluate-content-tab-li {
      font-size: 14px;
      font-weight: 500;
      color: #666666;
      cursor: pointer;
    }

    .evaluate-content-tab-li.evaluate-content-tab-li-ac {
      color: #ff6900;
    }

    .evaluate-content-tab-li + .evaluate-content-tab-li {
      margin-left: 40px;
    }

    .evaluate-content-tab-li:last-child {
      margin-left: auto;
    }

    .evaluate-content-li {
      width: 100%;
    }

    .evaluate-content-item {
      display: flex;
      margin-top: 30px;
    }

    .evaluate-item-avatar {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100px;
    }

    .evaluate-item-avatar img {
      width: 71px;
      height: 71px;
      border-radius: 50%;
    }

    .evaluate-item-avatar span {
      margin-top: 10px;
      font-size: 12px;
      font-weight: 500;
      color: #444444;
    }

    .evaluate-item-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-left: 30px;
    }

    .evaluate-item-content-stars {
      display: flex;
    }

    .evaluate-item-content-text {
      margin: 17px 0;
      font-size: 12px;
      font-weight: 500;
      color: #555555;
    }

    .evaluate-item-content-imgs {
      display: flex;
    }

    .evaluate-item-content-img {
      width: 80px;
      height: 80px;
      background: #f0eff4;
      border: 1px solid #e9e9e9;
    }

    .evaluate-item-content-img img {
      width: 100%;
      height: 100%;
    }

    .evaluate-item-content-img + .evaluate-item-content-img {
      margin-left: 15px;
    }

    .evaluate-item-content-actionbar {
      display: flex;
      margin-top: 15px;
      font-size: 12px;
      font-weight: 500;
      color: #888888;
    }
    .evaluate-item-content-actionbar li {
      @include flex-start;
      img {
        vertical-align: middle;
        margin-right: 5px;
      }
    }
    .evaluate-item-content-actionbar li + li {
      margin-right: 15px;
      cursor: default;
    }

    .nomessage {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 600px;
    }

    .evaluate-item-content-actionbar li:first-child {
      margin-right: auto;
    }
    .author-video {
      padding: 30px 0 30px 0;
    }
    .author-pic {
      display: flex;
      justify-content: space-between;
      padding: 0 0 30px 0;
    }
    .author-pic img {
      border-radius: 10px;
    }
  }

  .template_list {
    .content {
      .search {
        padding: 10px 20px;
      }

      .stencil_list {
        height: 500px;
        border-top: 1px solid #f7f7f7;
        font-size: 13px;
        .box {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-evenly;
        }

        .item {
          width: 45%;
          border-bottom: 1px solid #f7f7f7;
          padding: 15px;
          display: flex;
          align-items: center;

          .img {
            width: 60px;
            margin-right: 20px;
          }

          .content {
            flex: 1;
            line-height: 2;
            .title {
              font-size: 15px;
              font-weight: bold;
            }

            .template {
              font-size: 14px;
              color: #ccc;
            }

            .obj {
              color: #c3c3c3;
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
    }
  }
}
</style>
