var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"VIPcenter"},[_vm._m(0),_c('div',{staticClass:"bannerD"},[_c('div',{staticClass:"VIPcontrast"},[_c('div',{staticClass:"vip_title"},[_vm._v("会员服务权益对比")]),_c('div',{staticClass:"title"},_vm._l((_vm.titlelist),function(item){return _c('div',{key:item,staticClass:"title_item"},[_vm._v(" "+_vm._s(item)+" ")])}),0),_c('div',{staticClass:"introduce"},_vm._l((_vm.introducelist),function(v,i){return _c('div',{key:i,staticClass:"name_box"},[_c('div',{staticClass:"name",class:[
              v.special ? 'w' : '',
              v.title == '智能推文' ||
              v.title == '文库' ||
              v.title == '金句' ||
              v.title == '模板'
                ? 'rl'
                : '' ]},[_vm._v(" "+_vm._s(v.title)+" ")]),_c('div',{staticClass:"name_item_box"},_vm._l((v.child),function(v1,i1){return _c('div',{key:i1,staticClass:"item"},[(v1.name)?_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(v1.name)+" ")]):_vm._e(),_c('div',{staticClass:"vip_box"},_vm._l((v1.child),function(v1,i2){return _c('div',{key:i2,staticClass:"vip_item",class:typeof v1 == 'string' ? 'str' : ''},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(
                      v1 ? (typeof v1 == 'string' ? false : true) : false
                    ),expression:"\n                      v1 ? (typeof v1 == 'string' ? false : true) : false\n                    "}],attrs:{"src":require('assets/images/VIPcenter/have.png'),"alt":""}}),_vm._v(" "+_vm._s(v1 ? (typeof v1 == 'string' ? v1 : '') : '-')+" ")])}),0)])}),0)])}),0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"head"},[_c('h2',[_vm._v("VIP/SVIP会员")]),_c('div',{staticClass:"text"},[_vm._v(" VIP/SVIP会员在“成语极速版”等系列产品中，尊享AI应用专属通道、AIGC专属权益、资料下载专属优惠、网盘空间、专业服务专属优惠。 ")]),_c('div',{staticClass:"head_img"},[_c('img',{attrs:{"src":require("../assets/images/IdiomCoupon/vip.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/images/IdiomCoupon/svip.png"),"alt":""}})])])}]

export { render, staticRenderFns }